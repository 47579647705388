import type { HTMLProps, ReactNode } from "react";

import * as styles from "./Columns.styles";

interface ColumnsProps extends HTMLProps<HTMLDivElement> {
  /**
   * Allows wrapping of columns contained within if their width is greater than 12
   *
   * @default false
   */
  isMultiline?: boolean;
  /**
   * Removes margins from columns
   *
   * @default false
   */
  isMarginless?: boolean;
  /**
   * Centers all columns within if they do not take the full width
   *
   * @default false
   */
  isCentered?: boolean;
  /**
   * Enables display flex for preserving the mobile columns
   *
   * @default false
   */
  preserveMobileColumns?: boolean;
  /**
   * Content to render within columns, most likely Column
   *
   * @default undefined
   */
  children?: ReactNode;
}

/**
 * Documentation:
 * https://aviary.docs.fullscript.cloud/components/layout/Columns
 */
const Columns = ({
  isMultiline,
  isMarginless,
  isCentered,
  preserveMobileColumns,
  children,
  ...rest
}: ColumnsProps) => {
  const columnsStyles = [
    styles.columns,
    isMultiline && styles.multiline,
    isMarginless && styles.marginless,
    isCentered && styles.centered,
    preserveMobileColumns && styles.preserveMobileColumns,
  ];

  return (
    <div css={columnsStyles} {...rest}>
      {children}
    </div>
  );
};

export { Columns };
